import React, { useState } from 'react'
import Layout from '../components/layout'
import { Field, Input, Button, Pill, SelectList } from '@trexity/common-client/lib/components'
import Config from '../config'
import { COLORS } from '@trexity/common/color/constants'

const serverBaseUrl = Config.CF_IMPERSONATE_URL || Config.CLOUD_FUNCTIONS_BASE_URL

export default function Contact () {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [requestSent, setRequestSent] = useState(false)
  const [sendingRequest, setSendingRequest] = useState(false)

  const isFormValid = Boolean(name && email && message && type)

  const typeList = [
    { label: 'Driver', value: 'driver' },
    { label: 'Merchant', value: 'merchant' },
    { label: 'Other', value: 'other' }
  ]

  const cityList = [
    { label: 'Ottawa', value: 'Ottawa, ON' },
    { label: 'Toronto', value: 'Toronto, ON' }
  ]

  const createTicket = async () => {
    setSendingRequest(true)

    let serviceCity = cityList.find((obj) => obj.value === city)
    serviceCity = (serviceCity || cityList[0]).value

    let context = typeList.find((obj) => obj.value === type)
    context = (context || typeList[2]).value

    const response = await fetch(`${serverBaseUrl}/createSupportTicket`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'website-contact-form',
          name,
          email,
          message,
          serviceCity,
          context
        }
      })
    }).then((res) => res.json())

    if (response.data.result && response.data.result.ticket) {
      // success
      setSendingRequest(false)
      setRequestSent(true)
      setErrorMessage(null)
      setSuccessMessage('Thanks, we’ll be in touch soon.')
    } else {
      // failure
      setSendingRequest(false)
      setErrorMessage('Try again, or reach out to info@trexity.com')
    }
  }

  return (
    <Layout>
      <section id='contact'>
        <div className='row align-center justify-center'>
          <div className='col'>
            <div className="inner">
              <h1>Contact us</h1>
              <p>We’d love to hear from you. Fill out the form below and we’ll be in contact with you soon.</p>
              <Input
                value=''
                label="Name"
                disabled={requestSent}
                onChange={(evt) => setName(evt.target.value)}
                style={{ marginBottom: 20, borderRadius: 0 }}
              />
              <Input
                value=''
                label="Email"
                disabled={requestSent}
                onChange={(evt) => setEmail(evt.target.value)}
                style={{ marginBottom: 20, borderRadius: 0 }}
              />
              <SelectList
                marginBottom={20}
                bg={COLORS.TREXITY_BLUE}
                disabled={requestSent}
                placeholder="City"
                defaultValue={cityList[0].label}
                selectedItem={(item, index) => {
                  setCity(cityList[index].value)
                }}
                items={cityList.map(({ label }) => label)}
              />
              <SelectList
                marginBottom={20}
                bg={COLORS.TREXITY_BLUE}
                disabled={requestSent}
                placeholder="Type"
                defaultValue={typeList[2].label}
                selectedItem={(item, index) => {
                  setType(typeList[index].value)
                }}
                items={typeList.map(({ label }) => label)}
              />
              <Field
                animatingIn
                multi
                label="Message"
                disabled={requestSent}
                onChange={(text) => setMessage(text)}
                style={{ marginBottom: 20, borderRadius: 0 }}
              />
              <Button
                color="#6096BA"
                onClick={() => createTicket()}
                loading={sendingRequest}
                disableAnimations
                disabled={requestSent || !isFormValid}
                style={{ borderRadius: 0 }}
              >{requestSent ? 'Sent' : 'Send'}</Button>
              <div className="contact-status" style={{ marginTop: 20 }}>
                {successMessage ? <Pill label={successMessage} type="Delivered" /> : null}
                {errorMessage ? <Pill label={errorMessage} light type="Cancel" /> : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
